import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';

import StyledLink from '@makeship/core/lib/components/Link';
import Modal from '..';
import { H5, P2, Caption } from '../../Typography';
import { modalTheme } from '../../../styles/themes';

const ModalContent = styled.div`
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ModalCaption = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const CancelIcon = styled.div`
  position: absolute;
  right: 32px;
  top: 26px;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  text-align: left;
  margin: 16px 0;
`;

const PetitionHelpGuideBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
`;

const MailTo = styled(StyledLink.Primary)`
  color: ${({ theme }) => theme.colors.neutral7};
  text-decoration: underline;
`;

const PetitionHelpGuideModalTheme = {
  ...modalTheme,
  content: {
    ...modalTheme.content,
    borderRadius: '30px',
    width: '800px',
  },
};

type PetitionHelpGuideModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const PetitionHelpGuideModal: React.FC<PetitionHelpGuideModalProps> = ({
  isOpen,
  closeModal,
}: PetitionHelpGuideModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={closeModal} theme={PetitionHelpGuideModalTheme}>
    <ModalContent>
      <CancelIcon onClick={closeModal}>
        <Image src="/assets/icons/cancel-icon.svg" width="16" height="16" quality={90} />
      </CancelIcon>
      <ModalHeader>
        <H5>Petitions Help Guide</H5>
      </ModalHeader>
      <PetitionHelpGuideBody>
        <div>
          <ModalCaption>Deferred Charges</ModalCaption>
          <P2>
            When a petition launches as a campaign, your card will be charged the remaining balance, inclusive of
            shipping and tax. If the campaign fails, we will refund your pledge and deferred charge.
          </P2>
        </div>
        <div>
          <ModalCaption>Address Changes</ModalCaption>
          <P2>
            You can make changes to your shipping address for up to 30 days after the campaign ends. After this, we
            cannot guarantee address changes. You can do this in your Makeship account.
          </P2>
        </div>
        <div>
          <ModalCaption>Refunds</ModalCaption>
          <P2>
            Once a petition becomes a campaign, all sales are final and non-refundable. If a campaign does not achieve
            its funding goal, all orders will be refunded within 4-7 business days. For help with processing a petition
            refund, please contact{' '}
            <MailTo href="mailto:support@makeship.com" target="_blank">
              support@makeship.com
            </MailTo>
            .
          </P2>
        </div>
      </PetitionHelpGuideBody>
    </ModalContent>
  </Modal>
);

export default PetitionHelpGuideModal;
